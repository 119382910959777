<template>
    <div class="outContainer">
      <div class="back">
        <i class="el-icon-back" @click="back"></i>
        <span>{{ $route.query.id }}</span>
      </div>
      <!-- 筛选条件栏 -->
      <div class="search">
        <el-select class='select each' v-model="searchInfo.param.type" clearable placeholder="通讯类型"  @change="changeType">
          <el-option
            v-for="item in communicationOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="tableContainer">
        <!-- 表格数据 -->
        <el-table
        :data="tableList"
        height="100%"
        style="width: 100%;">
          <el-table-column align="center" label="指令" prop="cmd">
          </el-table-column>
          <el-table-column align="center" label="Mac" prop="mac">
              <template slot-scope="props">
                {{ props.row.mac ? props.row.mac : '--' }}
              </template>
          </el-table-column>
          <el-table-column align="center" label="固件版本" prop="firmVersion">
            <template slot-scope="props">
              {{ props.row.firmVersion ? props.row.firmVersion : '--' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="软件版本" prop="version">
            <template slot-scope="props">
              {{ props.row.version ? props.row.version : '--' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="通断电状态" prop="ifEnze">
            <template slot-scope="props">
              {{ props.row.ifEnze == 1 ? '通电' : (props.row.ifEnze == 0 ? '断电' : '--') }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="是否成功" prop="ifpm">
            <template slot-scope="props">
              {{ props.row.ifpm ? ( props.row.ifpm == 1 ? '成功' : '失败' ) : '--' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="创建时间" prop="createDatetime">
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <el-col :span="24" class="toolbar pagination">
        <el-pagination
          background
          layout=" prev , next , jumper"
          @current-change="handleCurrentChange"
          :page-size="10"
          style="float:right;"
        ></el-pagination>
      </el-col>
    </div>
</template>
  
  <script>
  import { getWifiSwitchRecords } from '@/api/public.js';
  import { timeChange } from '@/api/publicFun.js'
  export default {
    data() {
      return {
        searchInfo:{ // 搜索条件
          pageSize:10,
          pageNo:1,
          param:{
            deviceId:null,
            type:null,
          }
        },
        tableList:[], // 表格数据
        communicationOptions:[ // 通讯类型选项
          {value:1,label:'心跳'},
          {value:2,label:'主动控制通断电'},
          {value:3,label:'主动更新固件版本'},
        ],
      }
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.searchInfo.param.deviceId = this.$route.query.id;
      this.$nextTick(()=>{
        this.init();
      })
    },
  
    methods: {
      // 返回
      back(){
        this.$router.go(-1)
      },
      // 选择通讯类型
      changeType(e){
        this.searchInfo.pageNo = 1;
        this.searchInfo.param.type = e && e != "" ? e : null; 
        this.init();
      },
      // 获取通讯记录列表
      async init(){
        const loading = this.$loading({
            lock: true,
            text: '获取中',
            spinner: 'el-icon-loading',
            background:"rgba(0,0,0,0.5)"
        })
        await getWifiSwitchRecords(this.searchInfo).then(res=>{
          loading.close();
          if(res.success && res.data.list && res.data.list.length > 0){
            this.tableList = res.data.list;
            this.tableList.forEach(element => {
              element.createDatetime = timeChange(element.createDatetime,'seconds');;
            });
          }else{
            this.tableList = [];
            this.$message({
              message:'没有更多通讯记录了',
              type:'remind'
            })
          }
        })
      },
      // 分页
      handleCurrentChange(val){
        this.searchInfo.pageNo = val;
        this.init();
      }
    },
  };
  </script>
  <style scoped>
  
  </style>
  